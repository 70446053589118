<template>
    <v-row class="justify-center" no-gutters>
        <v-col cols="12" xs="12" sm="12" md="12" lg="10" xl="10" class="text-left pa-2">
            <v-row class="justify-left" no-gutters>
            <v-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8" class="align-center text-left ">
               <div class="align-center column">
                 <v-row class="ma-0 pa-0" >
                    <div class="heading4 secondary--text">Users</div>
                    <v-spacer />
                    <div><v-btn class="secondary"  @click="exportCSVFile(headersCSV,'users-Principality Stadium-swapp')">EXPORT USERS</v-btn></div>
                 </v-row>
                 <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-radio-group dark v-model="statusFilter" row>
                        <strong class="secondary--text mr-3">Status:</strong>
                        <v-radio
                            label="Approved"
                            value="APPROVED"
                        ></v-radio>
                        <!-- <v-radio
                            label="Pending"
                            value="PENDING"
                        ></v-radio> -->
                        <v-radio
                            label="Suspended"
                            value="SUSPENDED"
                        ></v-radio>
                        <v-radio
                            label="Archived"
                            value="ARCHIVED"
                        ></v-radio>
                        <v-radio
                            label="All"
                            value="ALL"
                        ></v-radio>
                    </v-radio-group>
                 </v-col>
               </div>  
           </v-col>
            <v-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8" class="text-left">
                    <!-- // * EVENT SEARCH / FILTERES -->
                    <v-row no-gutters class="pa-0 mb-4">
                        <v-col class="pr-1" cols="12" xs="" sm="" md="" lg="" xl=""><v-text-field clearable v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details dark filled background="white"  ></v-text-field></v-col>
                        <!-- <v-col class="pr-1" cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
                            <v-select clearable class="" hide-details="auto" label="Filter by Access Zone" multiple v-model="accessZoneFilter"   :items="accessZones"  item-value="value" item-text="text" dark filled background="white"  ></v-select>
                        </v-col> -->
                        <!-- <v-col class="pr-1" cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
                            <v-select clearable class="" hide-details="auto" label="Filter by Hotel" multiple v-model="hotelFilter"   :items="hotels" dark filled background="white"  ></v-select>
                        </v-col> -->
                    </v-row>                
                 <v-card>
                <v-data-table
                  :headers="computedHeaders"
                  :items="usersTableData"
                  :items-per-page="itemsPerPage"
                  class="elevation-1"
                  :search="search"
                     :mobile-breakpoint="0"
                >
                <template v-slot:[`item.actions`]="{ item }">
                    <div class="d-flex justify-end">
                        <div style="width: 50px !important;" ><v-icon small @click="formAction('Edit', item)" class="icons8-edit mr-2"></v-icon>
                         <!-- <v-icon small @click="deleteUser(item.userID)" class="icons8-trash"></v-icon> -->
                        </div>
                    </div>
                </template>
                 <!-- <template v-slot:[`item.accessZone`]="{ item }">
                  <span v-if="item.accessZone==='black'" > Access All Areas </span>
                  <span v-else class="text-capitalize" > {{item.accessZone}} </span>
                </template> -->
                <!-- <template v-slot:[`item.status`]="{ item }">
                  <span v-if="item.status==='PENDING'" > <v-btn x-small color="lightgrey" class="ma-0" @click="approveUser(item)">approve</v-btn> </span>
                  <span v-else > {{item.status}} </span>
                </template> -->
                </v-data-table>
                 </v-card>
                <!-- <pre align="left">{{ userForm }}</pre> -->
            </v-col>
            <transition
                name="custom-classes-transition"
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="animate__animated animate__fadeOut"
                mode="out-in"
            >
            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" class="text-left" v-if="showForm">
                <div class="darkgrey card  pa-4">
                    <v-row align="center">
                        
                    <div class="text-left heading6 ml-4">{{ formType }} User</div>
                        <v-col cols="12" class="text-left">
                            <v-form ref="users" v-model="valid" lazy-validation>
                            <v-select class="mb-3" :items="statusTypes" hide-details="auto" required label="Status" v-model="userForm.status" dark filled background="white" ></v-select>
                            <v-select class="mb-3" v-if="JSON.stringify(lookupValues !== '[]')" :items="userTypes" hide-details="auto" required label="User Type" v-model="userForm.userType" dark filled background="white" ></v-select>
                            <v-text-field class="mb-3" hide-details="auto" :rules="[(v) => !!v || 'Add a First Name for the user.']" required label="First Name" v-model="userForm.firstName" dark filled background="white" ></v-text-field>
                            <v-text-field class="mb-3" hide-details="auto" :rules="[(v) => !!v || 'Add a Last Name for the user.']" required label="Last Name" v-model="userForm.lastName" dark filled background="white" ></v-text-field>
                            <v-text-field class="mb-3" hide-details="auto" :rules="[(v) => !!v || 'Add an email for the user.']" required label="Email" v-model="userForm.email" dark filled background="white" ></v-text-field>
                            <!-- <v-text-field class="mb-3" hide-details="auto" :rules="[(v) => !!v || 'Add an address for the user.']" required label="Address" v-model="userForm.address" dark filled background="white" ></v-text-field>
                            <v-text-field class="mb-3" hide-details="auto" :rules="[(v) => !!v || 'Add an postcode for the user.']" required label="Postcode" v-model="userForm.postcode" dark filled background="white" ></v-text-field> -->
                            <v-text-field class="mb-3" hide-details="auto" :rules="[(v) => !!v || 'Add an contact number for the user.']" required label="Contact Number" v-model="userForm.contactNumber" dark filled background="white" ></v-text-field>
                            <!-- <template>
                                <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field class="mb-3" v-model="userForm.dob" label="Date of Birth" readonly v-bind="attrs" v-on="on" hide-details="auto" :rules="[(v) => !!v || 'Add a date of birth for the user.']" required dark filled background="white"></v-text-field>
                                    </template>
                                    <v-date-picker ref="picker" v-model="userForm.dob" :max="new Date().toISOString().substr(0, 10)" min="1950-01-01" @change="save"></v-date-picker>
                                </v-menu>
                            </template> -->
                            <!-- <v-select class="mb-3" v-if="JSON.stringify(lookupValues !== '[]')" :items="genderTypes" hide-details="auto" required label="Gender" v-model="userForm.sex" dark filled background="white" ></v-select>
                            <v-text-field class="mb-3" hide-details="auto" label="NHS Number" v-model="userForm.NHSnumber" dark filled background="white" ></v-text-field>
                            <v-select class="mb-3" v-if="JSON.stringify(lookupValues !== '[]')" :items="ethnicityTypes" hide-details="auto" required label="Ethnicity" v-model="userForm.ethnicity" dark filled background="white" ></v-select> -->
                            <v-text-field class="mb-3" hide-details="auto" :rules="[(v) => !!v || 'Add a company for the user.']" required label="Company" v-model="userForm.company" dark filled background="white" ></v-text-field>
                            <v-select class="mb-3" v-if="JSON.stringify(lookupValues !== '[]')" :items="visitorTypes" hide-details="auto" required label="Visitor Type" v-model="userForm.visitorType" dark filled background="white" ></v-select>
                            <v-text-field class="mb-3" hide-details="auto" :rules="[(v) => !!v || 'Add a job title for the user.']" required label="Job Title" v-model="userForm.jobTitle" dark filled background="white" ></v-text-field>
                            <!-- <v-select class="mb-3" :items="accessZones" hide-details="auto" required label="Access Zone" v-model="userForm.accessZone" dark filled background="white" ></v-select> -->
                            <!-- <v-select class="mb-3" :items="hotels" hide-details="auto" required label="Hotel" v-model="userForm.hotel" dark filled background="white" ></v-select> -->

                            </v-form>  
                        </v-col>
                        <v-col cols="12" class="d-flex align-center justify-end">
                            <div class="mr-2">
                                <v-btn depressed @click="showForm = false" class="grey">Close</v-btn>
                            </div>
                            <v-spacer />
                            <!-- <div class="mr-2">
                                <v-btn depressed v-if="formType === 'Edit'"  @click="deleteUser(userForm.userID)" color="white"><span class="black--text">Delete User</span></v-btn>
                            </div> -->
                            <div>
                                <v-btn depressed v-if="formType === 'Add'" @click="createUser()" color="secondary">Save User</v-btn>
                                <v-btn depressed v-if="formType === 'Edit'" @click="updateUser()" color="secondary">Update User</v-btn>
                            </div>
                        </v-col>
                        <!-- <v-col>
                            <pre align="left">{{ userForm }}</pre>
                        </v-col> -->
                    </v-row>
                </div>
            </v-col>
            </transition>
        </v-row>
        <transition
            name="custom-classes-transition"
            enter-active-class="animate__animated animate__fadeIn"
            leave-active-class="animate__animated animate__fadeOut"
            mode="out-in"
        >
        <appsnackbar :status="snackbar.status" :color="snackbar.color" :timeout="snackbar.timeout" :message="snackbar.message" :key="snackbarKey" />
        </transition>
        </v-col>
    </v-row>
</template>

<script>

// Clases
class SNACKBAR {
    constructor(status, color, message, timeout) {
        this.status = status;
        this.color = color;
        this.message = message;
        this.timeout = timeout;
    }
}

class USERFORM {
    constructor(status,id,userID, accessZone, firstName, lastName, contactNumber, email, company, jobTitle, visitorType, userLevel, userType, privacyPolicyConsent, sex, dob, NHSnumber, ethnicity, address, postcode, accountCreatedAt, updatedUserId, updatedDatetime, hotel) {
        this.status= status;
        this.id = id;
        this.userID = userID;
        this.accessZone = accessZone,
        this.firstName = firstName;
        this.lastName = lastName;
        this.contactNumber = contactNumber;
        this.email = email;
        this.company = company;
        this.jobTitle = jobTitle;
        this.visitorType = visitorType;
        this.userLevel = userLevel;
        this.userType = userType;
        this.privacyPolicyConsent = privacyPolicyConsent;
        this.sex = sex;
        this.dob = dob;
        this.NHSnumber = NHSnumber;
        this.ethnicity = ethnicity;
        this.address = address;
        this.postcode = postcode;
        this.accountCreatedAt = accountCreatedAt;
        this.updatedUserId = updatedUserId;
        this.updatedDatetime = updatedDatetime;
        this.hotel = hotel;
    }
}

export default {
    data(){
        return {
            accessZones: [
                {text:"RED" , value:"red"},
                {text:"AMBER", value:"amber"},
                {text:"GREEN", value:"green"},
                {text:"ACCESS ALL AREAS", value:"black"},
            ],
            accessZoneFilter: '',
            hotelFilter: '',
            statusFilter: 'ALL',
            search: '',
            itemsPerPage: 1,
            date : null ,
            menu:false,
            snackbarKey: 0,
            snackbar: {},
            showForm: false,
            formType: '', 
            userForm: {},
            genderTypes: [
                {text:"MALE", value:"MALE"},
                {text:"FEMALE", value:"FEMALE"},
                {text:"UNKNOWN", value:"UNKNOWN"},
            ],
            ethnicityTypes: [
                {text: "WHITE", value:"WHITE"},
                {text:"WHITE AND ASIAN", value:"WHITE AND ASIAN"},
                {text:"WHITE AND BLACK AFRICAN", value:"WHITE AND BLACK AFRICAN"},
                {text:"WHITE AND BLACK CARIBBEAN", value:"WHITE AND BLACK CARIBBEAN"},
                {text:"WHITE BRITISH", value:"WHITE BRITISH"},
                {text:"WHITE IRISH", value:"WHITE IRISH"},
                {text:"WHITE OTHER", value:"WHITE OTHER"},
                {text:"BANGLADESHI", value:"BANGLADESHI"},
                {text:"BLACK - AFRICAN", value:"BLACK - AFRICAN"},
                {text:"BLACK - CARIBBEAN", value:"BLACK - CARIBBEAN"},
                {text:"BLACK - OTHER", value:"BLACK - OTHER"},
                {text:"CHINESE", value:"CHINESE"},
                {text:"INDIAN", value:"INDIAN"},
                {text:"PAKISTANI", value:"PAKISTANI"},
                {text:"ISC - UNSPECIFIED", value:"ISC - UNSPECIFIED"},
                {text:"ANY OTHER ETHNIC CATEGORY", value:"ANY OTHER ETHNIC CATEGORY"},
                {text:"ANY OTHER MIXED GROUP", value:"ANY OTHER MIXED GROUP"},
                {text:"OTHER/MIXED", value:"OTHER/MIXED"},
                {text:"UNKNOWN", value:"UNKNOWN"},
            ],
            visitorTypes: [
                { text: "Broadcast", value:"Broadcast" },
                { text: "Written Media", value:"Written Media" },
                { text: "Photographers", value:"Photographers" },
                { text: "Medical", value:"Medical" },
                { text: "Principality Stadium Group Staff", value:"Principality Stadium Group Staff" },
                { text: "Technical", value:"Technical" },
                { text: "Police", value:"Police"},
                { text: "Team Support", value:" Team Support" },
                { text: "Stadium Staff", value: "Stadium Staff"},
                // { text: "Hotel Staff", value: "Hotel Staff"},
                { text: "Contractor", value:"Contractor"},
                { text: "Other", value:"Other" },                                                                        
            ],
            statusTypes : [
                // {text:"PENDING", value:"PENDING"},
                {text:"APPROVED", value: "APPROVED"},
                {text: "SUSPENDED", value:"SUSPENDED"},
                {text:"ARCHIVED", value: "ARCHIVED"},
            ],
            hotels: [ 'Clayton Hotel Cardiff', 'Holiday Inn Cardiff City', 'Village Hotel Tongwynlais ', 'Hilton Cardiff', 'Cardiff Marriott Hotel', 'Hotel Inn Cardiff North Merthyr Road', 'N/A'],
            headers: [
                { text: 'ID', value: 'userID', align: 'start', sortable: true, hidden: true, hide: true },
                { text: 'ID', value: 'id', align: 'start', sortable: true, hidden: true },
                { text: 'Status', value: 'status', align: 'start', sortable: true, hidden: false },
                { text: 'Access Zone', value: 'accessZone', align: 'start', sortable: true, hidden: true },
                { text: 'First Name', value: 'firstName', align: 'start', sortable: true, hidden: false },
                { text: 'Last Name', value: 'lastName', align: 'start', sortable: true, hidden: false },
                { text: 'Contact Number', value: 'contactNumber', align: 'start', sortable: true, hidden: false },
                { text: 'Email', value: 'email', align: 'start', sortable: true, hidden: false },
                { text: 'Company', value: 'company', align: 'start', sortable: true, hidden: false },
                { text: 'Job Title', value: 'jobTitle', align: 'start', sortable: true, hidden: false },
                { text: 'Visitor Type', value: 'visitorType', align: 'start', sortable: true, hidden: false }, 
                { text: 'Hotel', value: 'hotel', align: 'start', sortable: true, hidden: true },                               
                { text: 'User Level', value: 'userLevel', align: 'start', sortable: true, hidden: true },
                { text: 'User Type', value: 'userType', align: 'start', sortable: true, hidden: true },
                { text: 'Privacy Concent', value: 'privacyPolicyConsent', align: 'start', sortable: true, hidden: true },
                { text: 'Gender', value: 'sex', align: 'start', sortable: true, hidden: true },
                { text: 'DOB', value: 'dob', align: 'start', sortable: true, hidden: true },
                { text: 'NHS Number', value: 'NHSnumber', align: 'start', sortable: true, hidden: true },
                { text: 'Ethnicity', value: 'ethnicity', align: 'start', sortable: true, hidden: true },
                { text: 'Address', value: 'address', align: 'start', sortable: true, hidden: true },
                { text: 'Postcode', value: 'postcode', align: 'start', sortable: true, hidden: true },
                { text: 'Joined', value: 'accountCreatedAt', align: 'start', sortable: true, hidden: true },
                { text: 'Updated By', value: 'updatedUserId', align: 'start', sortable: true, hidden: true },
                { text: 'Updated Date', value: 'updatedDatetime', align: 'start', sortable: true, hidden: true },
                { text: 'Action', value: 'actions', align: 'end', hidden: false },
            ],
             headersCSV:{
                status: "Status",
                firstName: "User Forename",
                lastName: "User Surname",
                contactNumber: "Contact Number",
                email: "Email",
                company: "Company",
                jobTitle: "Job Title",
                visitorType: "Visitor Type",
                // hotel: "Hotel",
                userType: "User Type",
                privacyPolicyConsent: "Privacy Concern",
                accountCreatedAt: "Joined",
            },

            userTypes: [{text:'Admin', value:'admin'}, {text:'Standard', value:'standard'}],
            lookupValues:[],
            exportUsers:[],
            users: [],
            picker: new Date().toISOString().substr(0, 10),
            valid: true
        }
    },
     computed: {
        usersTableData() {
            var users = this.filteredUsersAdmin;
            //console.log('access filter: ' + this.accessZoneFilter)
            if ((this.accessZoneFilter !== '') && (this.accessZoneFilter !== undefined)) {
                users = users.filter(user => this.accessZoneFilter.includes(user.accessZone))
            }
            // if ((this.hotelFilter !== '') && (this.hotelFilter !== undefined)) {
            //     users = users.filter(user => this.hotelFilter.includes(user.hotel))
            // }
            if ((this.statusFilter !== '') && (this.statusFilter !== 'ALL')) {
                users = users.filter(user => user.status === this.statusFilter)
            }
            // console.log('access zone: ' + this.hotelFilter + ' type: ' + typeof this.hotelFilter)
            if (this.accessZoneFilter.length == 0 && this.hotelFilter.length == 0){
                users = this.filteredUsersAdmin;
            }
            // if( this.accessZoneFilter.length ==0){
            //     users=this.filteredUsersAdmin;
            // }
            return users.map(e => {
                return {
                    id:e.id,
                    status:e.status,
                    userID: e.userID,
                    accessZone: e.accessZone,
                    firstName: e.firstName,
                    lastName: e.lastName,
                    contactNumber: e.contactNumber,
                    email: e.email,
                    company: e.company,
                    jobTitle: e.jobTitle,
                    visitorType: e.visitorType,
                    userLevel: e.userLevel,
                    userType: e.userType,
                    privacyPolicyConsent: e.privacyPolicyConsent,
                    sex: e.sex,
                    dob: e.dob,
                    NHSnumber: e.NHSnumber,
                    ethnicity: e.ethnicity,
                    address: e.address,
                    postcode: e.postcode,
                    accountCreatedAt: e.accountCreatedAt,
                    updatedUserId: e.updatedUserId,
                    updatedDatetime: e.updatedDatetime,
                    // hotel: e.hotel
                  };
            });
        },
    },
    watch: {
        picker: {
            handler() {
                this.userForm.contactNumber = this.$moment(this.picker, 'YYYY-MM-DD').format('x');
            },
            deep: true
        }
    },
    methods: {
        save (date) {
            this.$refs.menu.save(date);
            this.user.dob = date;
        },
        formatExportUsers(){
             this.exportUsers = [];
             for (var  i=0; i < this.usersTableData.length; i++){
                 var status =""
                if ( this.usersTableData[i].status != null){
                     status =this.usersTableData[i].status;
                }else{
                    status = "Unknown"
                }
                var firstName =""
                if ( this.usersTableData[i].firstName != null){
                     firstName =this.usersTableData[i].firstName;
                }else{
                    firstName = "Unknown"
                }
                var lastName =""
                if ( this.usersTableData[i].lastName != null){
                     lastName =this.usersTableData[i].lastName;
                }else{
                    lastName = "Unknown"
                }
                var contactNumber ="";
                if(this.usersTableData[i].contactNumber !=null){
                    contactNumber = this.usersTableData[i].contactNumber;
                }
                var email ="";
                if(this.usersTableData[i].email !=null){
                    email = this.usersTableData[i].email;
                }
                var company ="";
                if(this.usersTableData[i].company !=null){
                    company = this.usersTableData[i].company;
                }
                var jobTitle ="";
                if(this.usersTableData[i].jobTitle !=null){
                    jobTitle = this.usersTableData[i].jobTitle;
                }
                var visitorType ="";
                if(this.usersTableData[i].visitorType !=null){
                    visitorType = this.usersTableData[i].visitorType;
                }
                // var hotel =""
                // if ( this.usersTableData[i].hotel !== null && this.usersTableData[i].hotel !== '' && this.usersTableData[i].hotel !== undefined){
                //      hotel =this.usersTableData[i].hotel;
                // }else{
                //     hotel = "Unknown"
                // }
                var userType ="";
                if(this.usersTableData[i].userType !=null){
                    userType = this.usersTableData[i].userType;
                }
                var privacyPolicyConsent ="";
                if(this.usersTableData[i].privacyPolicyConsent !=null){
                    if(this.usersTableData[i].privacyPolicyConsent === true){
                        privacyPolicyConsent = 'YES';
                    } else{
                        privacyPolicyConsent = 'NO'
                    }
                }
                var accountCreatedAt ="";
                if(this.usersTableData[i].accountCreatedAt !=null){
                    accountCreatedAt = this.$moment(this.usersTableData[i].accountCreatedAt).format('DD MMM YY | HH:mm:ss');
                }

                this.exportUsers.push({
                   status: status,
                   firstName: firstName,
                   lastName: lastName,
                   contactNumber: contactNumber,
                   email: email,
                   company: company,
                   jobTitle: jobTitle,
                   visitorType: visitorType,
                //    hotel: hotel,
                   userType: userType,
                   privacyPolicyConsent: privacyPolicyConsent,
                   accountCreatedAt: accountCreatedAt,
                })
            }
            return this.exportUsers;
        },
        exportCSVFile(headers, fileTitle) {
            //console.log("headers:" + JSON.stringify(headers, null, 2));
            var items = this.formatExportUsers();
            if (headers && items[0] != headers) {
                items.unshift(headers);
            }
            // console.log("items: " + JSON.stringify(items[1], null, 2));
            // Convert Object to JSON
            var jsonObject = JSON.stringify(items);

            var csv = this.convertToCSV(jsonObject);
            // console.log(JSON.stringify(csv, null, 2))
            var exportedFilenmae = fileTitle + ".csv" || "export.csv";
            var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

            if (navigator.msSaveBlob) {
                // IE 10+
                navigator.msSaveBlob(blob, exportedFilenmae);
            } else {
                var link = document.createElement("a");
                if (link.download !== undefined) {
                // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportedFilenmae);
                link.style.visibility = "hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                }
            }
        },
        // convert object to csv
        convertToCSV(objArray) {
            var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
            var str = "";
            for (var i = 0; i < array.length; i++) {
                var line = "";
                for (var index in array[i]) {
                if (line != "") line += ",";
                line += array[i][index];
                }
                str += line + "\r\n";
            }
            return str;
        },
        formAction(action, item) {
            this.formType = action;
            if (action === 'Add') {
                this.userForm = new USERFORM(null, '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', null, null, null, null); // clear user form
            } else {
                this.userForm = new USERFORM(item.status, item.id, item.userID, item.accessZone, item.firstName, item.lastName, item.contactNumber, item.email, item.company, item.jobTitle, item.visitorType, item.userLevel, item.userType, item.privacyPolicyConsent, item.sex, item.dob, item.NHSnumber, item.ethnicity, item.address, item.postcode, item.accountCreatedAt, item.updatedUserId, item.updatedDatetime, item.hotel);
            }
            this.showForm = true; // show form
        },
        async createUser() {
            if (this.$refs.users.validate()) {
                delete this.userForm.userID; // remove the id as we are creating a new user
                var result = await this.createDocument('users', this.userForm) // add menu through mixins
                if (result.code === 1) {
                    this.snackbar = new SNACKBAR(true,'success','User Added',2000); // update snack bar with success
                    this.snackbarKey++
                    this.userForm = new USERFORM(null, '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', null, null, null, null); // clear user form
                    this.showForm = false;
                } else {
                    this.snackbar = new SNACKBAR(true,'errorSnackbar','Error Adding User',3000); // update snack bar with error
                    this.snackbarKey++
                }
          }else{
            this.snackbar = new SNACKBAR(true,'errorSnackbar','Fill in every field in order to proceed.',3000); // update snack bar with error
            this.snackbarKey++
            }
        },
        sendApproveAccountEmail(user){
        const email_to = user.email;
        const name = user.firstName + ' ' + user.lastName;
        const url = `https://europe-west2-wru-swapp.cloudfunctions.net/sentAprrovedAccountEmail?email_to=${email_to}&name=${name}`;
            // console.log("url: " + url);
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json" },
            };
        fetch(url, requestOptions);
        },
        async approveUser(item) {
            //console.log('item before' + JSON.stringify(item, null,2));
            item.status = "APPROVED";
            item.updatedDatetime = this.$moment().toISOString();
            item.updatedUserId =  this.$store.getters.currUser.id;
            var result = await this.updateDocument('users', item); // update user through mixins
            if (result.code === 1) { // if update user was successful
                this.sendApproveAccountEmail(item);
                this.snackbar = new SNACKBAR(true,'success','User Approved',2000); // update snack bar with success
                this.snackbarKey++ // update snack bar component key to clear component
                this.userForm = new USERFORM(null,'','', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', null, null, null, null); // clear user form
                this.showForm = false; // hide form
                //console.log('item after' + JSON.stringify(item, null,2));
            } else {
                this.snackbar = new SNACKBAR(true,'errorSnackbar','Error Approving User',3000); // update snack bar with error
                this.snackbarKey++ // update snack bar component key to clear component
            }   
        },
        async updateUser() {
            //console.log("User Form" + JSON.stringify(this.userForm,null,2));
            if (this.$refs.users.validate()) {
                this.userForm.updatedDatetime = this.$moment().toISOString();
                this.userForm.updatedUserId =  this.$store.getters.currUser.id;
                if( this.userForm.userType === 'admin'){
                    this.userForm.userLevel = 100;
                } else {
                    this.userForm.userLevel = 10;
                }
                var result = await this.updateDocument('users', this.userForm); // update user through mixins
                if (result.code === 1) { // if update user was successful
                    this.snackbar = new SNACKBAR(true,'success','User Updated',2000); // update snack bar with success
                    this.snackbarKey++ // update snack bar component key to clear component
                    this.userForm = new USERFORM(null,'','', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', null, null, null, null); // clear user form
                    this.showForm = false; // hide form
                } else {
                    this.snackbar = new SNACKBAR(true,'errorSnackbar','Error Updating User',3000); // update snack bar with error
                    this.snackbarKey++ // update snack bar component key to clear component
                }
            }else{
                this.snackbar = new SNACKBAR(true,'errorSnackbar','Fill in every field in order to proceed.',3000); // update snack bar with error
                this.snackbarKey++;
            }
        },        
        async deleteUser(id) {
            var result = await this.deleteDocument('users', id); // delete user through mixins
            if (result.code === 1) {
                this.snackbar = new SNACKBAR(true,'success','User Deleted',1000); // update snack bar with success
                this.snackbarKey++ // update snack bar component key to clear component
            } else {
                this.snackbar = new SNACKBAR(true,'errorSnackbar','Error Deleting User',3000); // update snack bar with error
                this.snackbarKey++ // update snack bar component key to clear component
            }
        }
    },
    create() {
        // on create set default data objects
        this.userForm = new new USERFORM(null, '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', null, null, null, null); // clear user form
        this.snackbar = new SNACKBAR(false,'black','',3000); // set default
        this.readDocuments('lookupValues', 'lookupValues'); // get list of questions from database
    },
    async mounted() { 
        this.readDocuments('users', 'users'); // get list of users from database
        var itemsPerPage
        switch (this.$vuetify.breakpoint.name) {
            case 'xs': itemsPerPage = 1; break;
            case 'sm': itemsPerPage = 10; break;
            case 'md': itemsPerPage = 10; break;
            case 'lg': itemsPerPage = 20; break;
            case 'xl': itemsPerPage = 20; break;
            default: itemsPerPage = 1;
        }
        this.itemsPerPage = itemsPerPage
    }
}
</script>