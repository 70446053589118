<template>
  <v-row no-gutters class="d-flex align-center justify-center noprint" >
    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="noprint">
      <v-row class="d-flex align-center justify-center " no-gutters>
        <v-col
          cols="10"
          xs="10"
          sm="5"
          md="10"
          lg="10"
          xl="10"
          class="d-flex align-center mt-3 "
        >
          <table cellpadding="0" cellspacing="0" class="mb-8">
            <tr @click="goHome">
              <td align="left">
                <v-img
                  class=""
                  alt="Vue logo"
                  src="../assets/principalitystadium-logo.png"
                  max-height="90"
                  max-width="70"
                  contain
                ></v-img>
              </td>
              <td>
                <div to="/" class="pl-1 heading6 text-left">
                  <div
                    class="font-weight-bold white--text"
                    style="line-height: 26px"
                  >
                  Principality Stadium
                  </div>
                  <div class="secondary--text" style="line-height: 26px">
                   Pre-Event Screening
                  </div>
                </div>
              </td>
            </tr>
          </table>
        </v-col>
        <v-spacer/>
        <v-col cols="2" xs="2" sm="2" md="2" lg="2" xl="2" class="text-right noprint">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn dark icon v-bind="attrs" v-on="on">
                <v-icon class="display-1 white--text">icons8-menu</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-for="(item, i) in filteredMenuItems"
                :key="i"
                @click="pressButton(item.method)"
              >
                <v-list-item-title>
                  <v-icon :class="item.class">{{ item.icon }} </v-icon>
                  {{ item.text }}</v-list-item-title
                >
              </v-list-item>
                 <v-list-item v-if="showInstallBanner" @click="install()">
                <v-list-item-title>
                    <v-icon class="mr-4">icons8-download </v-icon>
                  Install App
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="privacyDialog = true">
                <v-list-item-title>
                  Terms and Conditions
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title class="lightgrey--text">
                  App version {{ LATEST_VERSION }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </v-col>
    <v-dialog
      style="z-index: 3000 !important;"
      v-model="privacyDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card class="background">
        <v-toolbar dark color="secondary heading5 black--text">
          Privacy and Terms & Conditions
          <v-spacer></v-spacer>

          <v-icon class="black--text" @click="privacyDialog = false">mdi-close</v-icon>
        </v-toolbar>

        <PrivacyTermsConditions />
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import PrivacyTermsConditions from "./termsAndConditions.vue";

let installEvent;
export default {
  name: "AppHeader",
  components: {
    PrivacyTermsConditions,
  },
  data() {
    return {
      showInstallBanner: false,
      LATEST_VERSION: "1.1.4",
      privacyDialog: false,
      menuItems: [
        {
          text: "Home",
          icon: "icons8-home-page",
          class: "mr-4",
          method: "goHome",
        },
        {
          text: "Admin",
          icon: "icons8-settings",
          class: "mr-4",
          method: "changeDashboard",
        },
        {
          text: "Profile",
          icon: "icons8-male-user",
          class: "mr-4",
          method: "goToProfile",
        },
        {
          text: "Support",
          icon: "icons8-online-support",
          class: "mr-4",
          method: "goToContact",
        },
        {
          text: "Log out",
          icon: "icons8-exit",
          class: "mr-4",
          method: "logout",
        },
      ],
    };
  },
  watch: {
    currentUser: {
      handler: function() {
        this.$store.getters.currentUser;
      },
      deep: true,
    },
  },
  computed: {
    dashboardButtonText() {
      if (this.$store.state.showAdminDashboard === true) {
        return "event home";
      } else {
        return "admin dashboard";
      }
    },
    filteredMenuItems() {
      var t = this;
      return this.menuItems.filter((item) => {
        if (
          t.$store.getters.currUser != null &&
          t.$store.getters.currUser.userLevel > 50
        ) {
          return this.menuItems;
        } else if (t.$store.getters.currUser === null) {
          return item.text.includes("Home");
        } else {
          return !item.text.includes("Admin");
        }
      });
    },
  },
  methods: {
    pressButton(val) {
      // console.log("Action button panels: " + JSON.stringify(val, null, 2));
      this[val]();
    },

    goHome() {
      if (this.$store.state.showAdminDashboard === true) {
        //this.$store.state.showAdminDashboard = false;
        // ! changed to commit
        this.$store.commit("setShowAdminDashboard", false);
      }
      this.$router.push("/").catch((error) => {
        console.log(error.message);
      });
    },
    logout() {
      this.$firebase.auth.signOut().then(() => {
        this.$router.push("/access").catch((err) => {
          console.log("Route error: " + err);
        });
        this.$store.commit("setUser", null);
      });
    },
    changeDashboard() {
      //this.$store.state.showAdminDashboard = true;
      // ! changed to commit
      this.$store.commit("setShowAdminDashboard", true);

      this.$router.push("/admin/passes").catch((err) => {
        console.log("Router error: " + err);
      });
    },
    goToProfile() {
      this.$router.push("/user-profile").catch((err) => {
        console.log("Router error: " + err);
      });
    },
    goToContact() {
      this.$router.push("/contact-us").catch((err) => {
        console.log("Router error: " + err);
      });
    },
    install() {
      this.showInstallBanner = false;
      installEvent.prompt();
      installEvent.userChoice.then(() => {
        installEvent = null;
      });
    },
  },
  created() {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      installEvent = e;
      this.showInstallBanner = true;
    });
  },
};
</script>
