<template>
    <v-row class="justify-center" no-gutters>
        <v-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4" class="text-left">
          <div style="" class="pass text-center pa-5 ma-5">
            <div class="heading4 font-weight-bold">
              <div v-if="JSON.stringify(pass) !== '{}'" class="text-uppercase">
                <span v-if="pass.status === 'APPROVED'" class="success--text">{{ pass.status }}</span>
                <span v-if="pass.status === 'DECLINED'" class="red--text">{{ pass.status }}</span>
              </div>
              <div v-else class="black--text">NO PASS FOUND</div>
            </div>
            <v-icon v-if="pass.status === 'APPROVED'" class="success--text" style="font-size: 100px">icons8-ok</v-icon>
            <v-icon v-if="pass.status === 'DECLINED'" class="red--text" style="font-size: 100px">icons8-no-entry</v-icon>
            <div class="heading4 text-center background--text">
              <span v-if="JSON.stringify(event) !== '{}'">{{ $moment(event.eventDate, 'x').format('DD MMM YYYY') }}</span>
              <span v-else>-</span>
            </div>
            <div class="heading5 text-center black--text font-weight-bold">
              <span v-if="JSON.stringify(event) !== '{}'" :class="{ 'black--text': pass.status === 'APPROVED', 'error--text': pass.status === 'DECLINED'}">{{ event.eventName }}</span>
              <span v-else>-</span>              
            </div>
            <div class="d-flex justify-center flex-column ">
              <div class="text-center container d-flex justify-center">
            <table id="pass" class="background--text mt-5 title" border="0" align="center" width="100%">
              <tr>
                <td align="left" width="45%" class="font-weight-bold">Venue:</td>           
                <td align="left">
                  <span v-if="JSON.stringify(venue) !== '{}'">{{ venue.venueName }}</span>
                  <span v-else>-</span>
                </td>
              </tr>
              <tr>
                <td align="left" width="45%" class="font-weight-bold">Name:</td>
                <td align="left">
                  <span v-if="JSON.stringify(user) !== '{}'">{{ user.firstName}} {{ user.lastName }}</span>
                  <span v-else>-</span>
                </td>
              </tr>
              <tr>
                <td align="left" width="45%" class="font-weight-bold">Job Title:</td>           
                <td align="left">
                  <span v-if="JSON.stringify(user) !== '{}'">{{ user.jobTitle }}</span>
                  <span v-else>-</span>
                </td>
              </tr>
              <tr>
                <td align="left" width="45%" class="font-weight-bold">Organisation:</td>          
                <td align="left">
                  <span v-if="JSON.stringify(user) !== '{}'">{{ user.company }}</span>
                  <span v-else>-</span>
                </td>
              </tr>
              <tr>
                <td align="left" width="45%" class="font-weight-bold">Visitor Type:</td>             
                <td align="left">
                  <span v-if="JSON.stringify(user) !== '{}'">{{ user.visitorType }}</span>
                  <span v-else>-</span>
                </td>
              </tr>
            </table>
              </div>
              <div class="d-flex align-center justify-center mt-5">
                <!-- <div><v-img class="" alt="Vue logo" src="../assets/svg-logo.svg" max-height="50" max-width="50" contain></v-img></div> -->
                <div class="ml-5 text-left background--text font-weight-normal"><strong>WRU Pre-Event Screening</strong> <strong>REF:</strong> {{ pass.id }}</div>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="text-left noprint">
        <!-- <v-col class="ml-0 ml-5-md text-left"> -->
              <div class="body-2 card lighten-1 ma-5 pa-5" style="border-radius: 20px;">
              <div class="d-flex justify-space-between">
                <div class="heading4 text-left">Next Steps</div>
                <v-btn class="secondary" large to="/events/checkinevent">Back</v-btn>   
              </div>
              <div class="white--text text-left body1 mt-4" v-if="pass.status === 'DECLINED'">
                  Your Event Screening application has been Declined. 
                  <br />
                  <br />
                  Please contact your line manager or contact the WRU Accreditation Dept: 
                  <a href="mailto:accreditation@wru.wales"><strong class="secondary--text">accreditation@wru.wales</strong></a>
              </div>
                  <div class="white--text text-left body1 mt-4" v-if="pass.status === 'APPROVED'">
                    At the Venue, you will be required to show this notification to security.
                    <br /><br />Remember you still need to apply for Event specific accreditation.
                    <div class="heading6 mt-3 secondary--text">Accessing this Pass on a Smartphone or Tablet</div>
                    If you are using this App on a Laptop or Desktop, click the <strong>Email App Link</strong> and then open your email client on your Smartphone or Tablet.
                    If you do not recieve the App Link then you can visit <a href="https://wruevents.swapp.work/"><strong class="secondary--text">https://wruevents.swapp.work/</strong></a> on your Smartphone or Tablet.
                    <br /><br /><v-btn class="white" v-if="showEmailButton === true" @click="emailAppLink()">Email App Link</v-btn>
                    <div class="heading6 mt-3 secondary--text mt-5">Print off the pass</div>
                    If you have a problem accessing the App on your smartphone, then you can print off the pass and show it to security.
                    <br /><br /><v-btn @click="printPass" class="white">Print Now</v-btn><br /><br />
                    </div>

              </div>
      </v-col>
        <transition
      name="custom-classes-transition"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
      mode="out-in"
    >
      <appsnackbar
        :status="snackbar.status"
        :color="snackbar.color"
        :timeout="snackbar.timeout"
        :message="snackbar.message"
        :key="snackbarKey"
      />
    </transition>
    </v-row>
</template>

<script>
class SNACKBAR {
  constructor(status, color, message, timeout) {
    this.status = status;
    this.color = color;
    this.message = message;
    this.timeout = timeout;
  }
}
export default {
  data(){
    return {
      user: {},
      venue: {},
      event: {},
      pass: {},
      snackbarKey: 0,
      snackbar: {},
      showEmailButton: true
    }
  },
  methods: {
    emailAppLink(){
      const email_to = this.$store.getters.currUser.email;
       const url = `https://europe-west2-wru-events.cloudfunctions.net/sendEmailAppLink?email_to=${email_to}`;
        // console.log("url: " + url);
        const requestOptions = {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        };
        fetch(url, requestOptions);
        this.step++;
        this.snackbar = new SNACKBAR(
          true,
          "success",
          "Your app link email has been sent.",
          2000
        ); // update snack bar with success
        this.showEmailButton = false;
        this.snackbarKey++;
    }
  },
  async mounted() { 
    this.user = await this.readDocumentById('users', this.$store.getters.currUser.userID); // get list of events from database  
    this.pass = await this.readDocumentById('passes', this.$route.params.id); // get list of events from database  
    this.event = await this.readDocumentById('events', this.pass.eventId);
    this.venue = await this.readDocumentById('venues', this.event.venueId);
      
    //this.readDocumentById('events', 'event', this.$route.params.id); // get list of events from database
    //this.readDocumentById('venues', 'venue', this.$route.params.id); // get list of events from database    
  }

}
</script>



<style>
  .pass {
    background: white !important;
    border: 2px solid white !important;
    border-radius: 20px !important;
  }
  #pass > td {
    border: 1px solid grey !important;
  }



</style>