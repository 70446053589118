<template>
    <v-row class="justify-center" no-gutters>
        <v-col cols="12" xs="12" sm="12" md="12" lg="10" xl="10" class="text-left pa-2">
            <v-row class="justifty-left" no-gutters>
                <v-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8" class="align-center text-left ">
                    <!-- // * PASSES / Filter -->
                    <div class="align-center column">
                        <v-row class="ma-0 pa-0" >
                            <div class="pb-1 heading4 secondary--text">Passes</div>
                            <v-spacer/> 
                            <div><v-btn class="secondary mt-2" @click="exportCSVFile(headersCSV,'passes-Principality Stadium-swapp')">EXPORT PASSES</v-btn></div>
                        </v-row>
                        <v-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8">
                            <v-radio-group dark v-model="statusFilter" row>
                                <strong class="secondary--text mr-3">Status:</strong>
                                <v-radio
                                    label="Approved"
                                    value="APPROVED"
                                ></v-radio>
                                <v-radio
                                    label="Declined"
                                    value="DECLINED"
                                ></v-radio>
                                <v-radio
                                    label="All"
                                    value="ALL"
                                ></v-radio>
                            </v-radio-group>
                        </v-col>                           
                    </div>
                    <!-- * SEARCH AND FILTERS * --> 
                    <v-row no-gutters class="pa-0 mb-4">
                        <v-col class="pr-1 pt-1" cols="12" xs="12" sm="6" md="6" lg="6" xl="6"><v-text-field clearable v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details dark filled background="white"  ></v-text-field></v-col>
                        <v-col class="pr-1 pt-1" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                            <v-select clearable class="" hide-details="auto" label="Filter by Event"  v-model="eventFilter"   :items="filteredEventsAdmin"  item-value="id"  dark filled background="white"  >
                                <template slot="selection" slot-scope="data">
                                {{ data.item.eventName }} - {{ formatDatetime(data.item.eventDate, 'x', 'DD MMM YYYY') }}
                                </template>
                                <template slot="item" slot-scope="data">
                                {{ data.item.eventName }} - {{ formatDatetime(data.item.eventDate, 'x', 'DD MMM YYYY') }}
                                </template>
                            </v-select>
                        </v-col>
                        <!-- <v-col class="pt-1 pr-1" cols="12" xs="12" sm="6" md="6" lg="3" xl="3">
                            <v-select clearable class="" hide-details="auto" label="Filter by Daily COVID Test Result" multiple v-model="testFilter"   :items="testAnswers"  dark filled background="white"  >
                            </v-select>
                        </v-col> -->
                        <!-- <v-col class="pt-1 pr-1" cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
                            <v-select clearable class="" hide-details="auto" label="Filter by Venue" multiple v-model="venueFilter"   :items="venuesList" dark filled background="white"  ></v-select>
                        </v-col> -->
                    </v-row>
                    <v-card>
                    <!-- @click:row="userClick" -->
                        <!-- * DATA TABLE -->
                        <v-data-table
                            :headers="computedHeaders"
                            :items="passesTableData"
                            :items-per-page="itemsPerPage"
                            class="elevation-1"
                            :search="search"
                            :mobile-breakpoint="0"
                        >
                            <template v-slot:[`item.fullName`]="{ item }">
                                <div class="d-flex justify-start">
                                    <div>
                                        <a @click="userClick(item.userId)" class="black--text">{{ item.fullName}}</a>
                                    </div>
                                </div>
                            </template>
                            <template v-slot:[`item.answers`]="{ item }">
                                <div class="d-flex justify-center">
                                    <v-icon small @click="showAnswers(item.answers)" class="icons8-help display-1"></v-icon>
                                </div>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <div class="d-flex justify-end">
                                    <div style="width: 50px !important;" ><v-icon small @click="formAction('Edit', item)" class="icons8-edit mr-2"></v-icon>
                                    <v-icon small @click="deletePass(item.id, item.userId)" class="icons8-trash"></v-icon>
                                    </div>
                                </div>
                            </template>

                            <template v-slot:[`item.userId`]="{ item }">
                              <span v-if="lookupUser(item.userId, 'hotel') !== undefined && lookupUser(item.userId, 'hotel') !== ''"> {{lookupUser(item.userId, 'hotel')}}</span>
                              <span v-else>Unknown</span>
                            </template>
                            
                            <template v-slot:[`item.note`]="{ item }">
                                <v-tooltip bottom max-width="200" v-if="((item.note !== null) && (item.note !== '') && (item.note !== undefined))">
                                    <template v-slot:activator="{ on }">
                                        <v-icon class="icons8-hint"  v-on="on">
                                        </v-icon>
                                    </template>
                                    <span>{{ item.note }}</span>
                                </v-tooltip>
                            </template>
                            <template v-slot:[`item.status`]="{ item }">
                            <span v-if="item.status === 'APPROVED'"><v-icon :style="{'background-image': 'url(' + require('@/assets/circle.svg') + ')'}" class="iconbackground display-1 success--text icons8-ok"></v-icon></span>
                            <span v-if="item.status === 'DECLINED'"><v-icon :style="{'background-image': 'url(' + require('@/assets/circle.svg') + ')'}" class="iconbackground display-1 red--text icons8-no-entry"></v-icon></span>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-col>
                <transition
                    name="custom-classes-transition"
                    enter-active-class="animate__animated animate__fadeIn"
                    leave-active-class="animate__animated animate__fadeOut"
                    mode="out-in"
                >
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" class="pa-4 text-left" v-if="showForm">
                    <div class="darkgrey card pa-4">
                        <v-row align="center">
                            <v-col cols="12">
                            <div class="heading6">{{ formType }} Pass</div>
                            <div class="body2">{{ lookupUser(passForm.userId, 'fullName') }}</div>
                            <div class="body1">{{ lookupEvent(passForm.eventId, 'eventName') }}</div>
                            <div class="body1">{{ lookupVenue(passForm.venueId, 'venueName') }} - {{ $moment(passForm.eventDate, 'x').format('DD-MMM-YY') }}</div>
                            <div class="body1">{{ passForm.date }}</div>
                            <v-row align="center">
                                <v-col cols="12" class="text-left">
                                <v-form ref="passes" v-model="valid" lazy-validation>
                                    <v-select :rules="[(v) => !!v || 'Select a status']" required hide-details="auto" label="Status" v-model="passForm.status" dark filled background="white" :items="status" />
                                    <v-textarea class="mt-2" :rules="[(v) => !!v || 'Add a note']" required hide-details="auto" label="Note" placeholder="You must enter a note if you change the Status of a Pass" v-model="passForm.note" dark filled background="white" ></v-textarea>
                                    </v-form>
                                </v-col>
                                <v-col cols="12" class="d-flex align-center justify-end">
                                    <div class="mr-2">
                                        <v-btn depressed @click="showForm = false" class="grey">Close</v-btn>
                                    </div>
                                    <v-spacer />
                                    <div class="mr-2">
                                        <v-btn depressed v-if="formType === 'Edit'"  @click="deletePass(passForm.id, passForm.userId)" color="white"><span class="black--text">Delete Pass</span></v-btn>
                                    </div>
                                    <div>
                                        <v-btn depressed v-if="formType === 'Add'" @click="createPass()" color="secondary">Save Pass</v-btn>
                                        <v-btn depressed v-if="formType === 'Edit'" @click="updatePass()" color="secondary">Update Pass</v-btn>
                                    </div>
                                </v-col>
                            </v-row>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
                </transition>
                
            </v-row>
            <!-- <pre align="left">{{ passForm }}</pre> -->
            <transition
                name="custom-classes-transition"
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="animate__animated animate__fadeOut"
                mode="out-in"
            >
            <appsnackbar :status="snackbar.status" :color="snackbar.color" :timeout="snackbar.timeout" :message="snackbar.message" :key="snackbarKey" />
            </transition>
            <v-dialog v-model="userDialog" width="500" style="z-index: 3000 !important;">
                <v-card>
                    <v-card-title class="headline secondary  white--text">
                    {{ userDetails.fullName }}
                    </v-card-title>
                    <v-card-text>
                        <v-simple-table class="mt-5">
                            <template v-slot:default>
                                <tbody>
                                    <tr>
                                        <td>Contact Number</td>
                                        <td><a class="secondary--text" :href="'tel:'+userDetails.contactNumber">{{ userDetails.contactNumber }}</a></td>
                                    </tr>
                                    <tr>
                                        <td>Email</td>
                                        <td><a class="secondary--text" :href="'mailto:'+userDetails.email">{{ userDetails.email }}</a></td>
                                    </tr>
                                    <tr>
                                        <td>Job Title</td>
                                        <td>{{ userDetails.jobTitle }}</td>
                                    </tr>
                                    <tr>
                                        <td>Company</td>
                                        <td>{{ userDetails.company }}</td>
                                    </tr>
                                    <tr>
                                        <td>Visitor Type</td> 
                                        <td>{{ userDetails.visitorType }}</td>
                                    </tr>
                                    <tr>
                                        <td>Joined</td>
                                        <td>{{ userDetails.accountCreatedAt }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="secondary" class="buttonmin" @click="userDialog = false">Close</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="answersDialog" width="500" style="z-index: 3000 !important;">
                <v-card>
                    <v-card-title class="heading6 secondary  white--text">
                    Pre-event Screening Questions
                    </v-card-title>
                    <v-card-text class="pt-5">
                        <div v-for="answer in answers" :key="answer.id" class="mb-5">
                            <div class="font-weight-normal">{{ lookupQuestion(answer.id).questionName }}</div>
                            <div :class="
                                
                                    answer.questionCorrect.includes(answer.questionResult) ? 'success--text' : 'error--text'
                                
                            ">
                                <div><strong>User's Answer: </strong>  {{ answer.questionResult }}</div>
                                <div><strong>Accepted Answer(s): </strong> <span v-for="answer in answer.questionCorrect" :key="answer">{{ answer }}, </span></div>
                            </div>
                        </div>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="secondary" class="buttonmin" @click="answersDialog = false">Close</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>            
            <v-dialog
              v-model="deleteDialog"
              persistent
              max-width="300"
              style="z-index: 3000 !important;"
              >
              <v-card>
                <v-card-title  class="background white--text">
                 Delete Pass
                </v-card-title>
                <v-card-text class="mt-6"> <p class="subtitle1">Are you sure you want to delete the pass for <strong>{{deleteName}}</strong>?</p></v-card-text>
                <v-card-actions class="">
                  <v-spacer></v-spacer>
                  <v-btn class="lightgrey" @click="noAction()"> No</v-btn>
                  <v-btn class="secondary" @click="yesAction()">Yes</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
        </v-col>
    </v-row>
</template>

<script>

// Clases
class SNACKBAR {
    constructor(status, color, message, timeout) {
        this.status = status;
        this.color = color;
        this.message = message;
        this.timeout = timeout;
    }
}

class PASSFORM {
    constructor(id, userId, eventId, eventDate, venueId, status, answers, note, createdUserId, createdDatetime, updatedUserId, updatedDatetime) {
        this.id = id;
        this.userId = userId;
        this.eventId = eventId;
        this.eventDate = eventDate;
        this.venueId = venueId;
        this.status = status;
        this.answers = answers;
        this.note = note;
        this.createdUserId = createdUserId;
        this.createdDatetime = createdDatetime;
        this.updatedUserId = updatedUserId;
        this.updatedDatetime = updatedDatetime;
    }
}

export default {
    data(){
        return {
            deleteDialog: false,
            deleteName: '',
            deleteId: '',
            userDialog: false,
            answersDialog:false,
            userDetails: {},
            search: '',
            venueFilter: '',
            eventFilter: '',
            testFilter: '',
            statusFilter: 'ALL',
            itemsPerPage: 1,
            snackbarKey: 0,
            snackbar: {},
            showForm: false,
            formType: '', 
            passForm: {},
            venuesList: ['Principality Stadium', 'Cardiff City Stadium', 'Parc y Scarlets', 'Cardiff Blues'],
            headers: [
                { text: 'Status', value: 'status', align: 'start', sortable: false, hidden: false },
                { text: 'ID', value: 'id', align: 'start', sortable: true, hidden: true },
                ////{ text: 'User', value: 'userId', align: 'start', sortable: true, hidden: false },
                { text: 'User Forename', value: 'firstName', align: 'start', sortable: true, hidden: false },
                { text: 'User Surname', value: 'lastName', align: 'start', sortable: true, hidden: false },
                { text: 'Hotel', value: 'userId', align: 'start', sortable: true, hidden: true },
                { text: 'Event', value: 'eventName', align: 'start', sortable: true, hidden: false },
                // { text: 'Date', value: 'eventDate', align: 'start', sortable: true, hidden: false },
                { text: 'Venue', value: 'venueName', align: 'start', sortable: true, hidden: false, hide: true },
                { text: 'Questions', value: 'answers', align: 'start', sortable: false, hidden: false, hide: false },
                { text: 'Note', value: 'note', align: 'start', sortable: true, hidden: false, hide: true },
                { text: 'Created By', value: 'createdUserId', align: 'start', sortable: true, hidden: true },
                { text: 'Created Date', value: 'createdDatetime', align: 'start', sortable: true, hidden: true },
                { text: 'Updated By', value: 'updatedUserId', align: 'start', sortable: true, hidden: true },
                { text: 'Updated Date', value: 'updatedDatetime', align: 'start', sortable: true, hidden: true },
                { text: 'Action', value: 'actions', align: 'end', hidden: false, hide: true },
            ],
            headersCSV:{
                forename: "Forename",
                surname: "Surname",
                eventName: "Event",
                venueName: "Venue",
                note: "Note",
                createdUserId: "Created By",
                createdDatetime: "Created Date",
                updatedUserId: "Updated By",
                updatedDatetime: "Updated Date",
            },
            exportPasses: [],
            passes: [],
            venues: [],
            events: [],
            questions: [],
            users: [],
            answers: [],
            status: ['DECLINED', 'APPROVED'],
            testAnswers: ['POSITIVE', 'NEGATIVE', 'N/A'],
            valid: true
        }
    },
    computed: {
        passesTableData() {
            var passes = this.passes;
            if ((this.eventFilter !== '') && (this.eventFilter !== undefined)) {
                passes = passes.filter(pass => pass.eventId === this.eventFilter);
            }
        
            if ((this.venueFilter !== '') && (this.venueFilter !== undefined)) {
                passes = passes.filter(pass => pass.venueName === this.venueFilter);
            }
            if ((this.statusFilter !== '') && (this.statusFilter !== 'ALL')) {
                passes = passes.filter(pass => pass.status === this.statusFilter);
            }
            if ((this.testFilter !== '') && (this.testFilter !== undefined)) {
                passes = passes.filter(pass => this.testFilter.includes(pass.answers[5].questionResult));
            }
            //  if ((this.venueFilter !== '') && (this.venueFilter !== undefined)) {
            //     passes = passes.filter((pass) => {
            //         var userPass = this.lookupUser(pass.userId, 'hotel');
            //         return this.venueFilter.includes(userPass);
            //     })
            // }
            if ( this.eventFilter == null){
                passes = this.passes
            }
               
            return passes.map(e => {
                return {
                    id: e.id,
                    status: e.status,
                    firstName: this.lookupUser(e.userId, 'firstName') ,
                    lastName: this.lookupUser(e.userId, 'lastName'),
                    userId: e.userId,
                    eventId: e.eventId,
                    eventName: this.lookupEvent(e.eventId, 'eventName'),
                    eventDate: e.eventDate, //this.$moment(e.eventDate, 'x').format('DD/MM'),
                    venueId: e.venueId, 
                    venueName: this.lookupVenue(e.venueId, 'venueName'), 
                    answers: e.answers,
                    note: e.note,
                    createdUserId: e.createdUserId,
                    updatedUserId: e.updatedUserId,
                    createdDatetime: e.createdDatetime,
                    updatedDatetime: this.$moment(e.updatedDatetime, 'x').format('DD/MM/YYYY'),
                };
            });
        }
    },
    methods: {
        formatExportPasses(){
             this.exportPasses = [];
             for (var  i=0; i < this.passesTableData.length; i++){
                var forename =""
                if ( this.lookupUser(this.passesTableData[i].userId, 'firstName') != ""){
                     forename =this.lookupUser(this.passesTableData[i].userId, 'firstName');
                }else{
                    forename = "Unknown"
                }
                var surname =""
                if ( this.lookupUser(this.passesTableData[i].userId, 'lastName') != ""){
                     surname =this.lookupUser(this.passesTableData[i].userId, 'lastName');
                }else{
                    surname = "Unknown"
                }
                // var DOB =""
                // if ( this.lookupUser(this.passesTableData[i].userId, 'dob') != ""){
                //      DOB =this.lookupUser(this.passesTableData[i].userId, 'dob');
                // }else{
                //     DOB = "Unknown"
                // }
                
                var eventName="";
                if ( this.lookupEvent(this.passesTableData[i].eventId, 'eventName') !=""){
                     eventName =  this.lookupEvent(this.passesTableData[i].eventId, 'eventName');
                }else{
                     eventName = "Unknown";
                }

                var venueName="";
                if ( this.lookupVenue(this.passesTableData[i].venueId, 'venueName') !=""){
                     venueName =  this.lookupVenue(this.passesTableData[i].venueId, 'venueName');
                }else{
                    venueName = "Unknown";
                }

                var note="";
                 if (this.passesTableData[i].note !=null){
                     note = this.passesTableData[i].note;
                 }
               
                var createdUserId="";
                 if ( this.passesTableData[i].createdUserId !=null){
                     createdUserId = this.lookupUser(this.passesTableData[i].createdUserId, 'firstName') + " " +  this.lookupUser(this.passesTableData[i].createdUserId, 'lastName');
                }else{
                     createdUserId = "Unknown"
                }

                var createdDatetime="";
                if (this.passesTableData[i].createdDatetime !=null) {
                     createdDatetime = this.$moment(this.passesTableData[i].createdDatetime, "x").format("DD MMM YY | HH:mm:ss");
                } else {
                     createdDatetime = "Unknown";
                }

                var updatedUserId="";
                // console.log ( "hi " + JSON.stringify(this.passesTableData[i], null,2 ));
                if (this.passesTableData[i].updatedUserId !=null){
                    updatedUserId = this.lookupUser(this.passesTableData[i].updatedUserId, 'firstName')  + " " +  this.lookupUser(this.passesTableData[i].updatedUserId, 'lastName');
                }else{
                    updatedUserId = "Unknown"
                }
                
                var updatedDatetime;
                if (this.passesTableData[i].updatedDatetime != "") {
                     updatedDatetime = this.passesTableData[i].updatedDatetime;
                } else {
                     updatedDatetime = "Unknown";
                }
                this.exportPasses.push({
                    status: status,
                    forename: forename,
                    surname: surname,
                    // DOB:DOB,
                    eventName: eventName,
                    venueName: venueName,
                    note: note,
                    createdUserId: createdUserId,
                    createdDatetime: createdDatetime,
                    updatedUserId: updatedUserId,
                    updatedDatetime: updatedDatetime,
                })
            }
            return this.exportPasses;
        },
        exportCSVFile(headers, fileTitle) {
            //console.log("headers:" + JSON.stringify(headers, null, 2));
            var items = this.formatExportPasses();
            if (headers && items[0] != headers) {
                items.unshift(headers);
            }
            // console.log("items: " + JSON.stringify(items[1], null, 2));
            // Convert Object to JSON
            var jsonObject = JSON.stringify(items);

            var csv = this.convertToCSV(jsonObject);
            // console.log(JSON.stringify(csv, null, 2))
            var exportedFilenmae = fileTitle + ".csv" || "export.csv";
            var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

            if (navigator.msSaveBlob) {
                // IE 10+
                navigator.msSaveBlob(blob, exportedFilenmae);
            } else {
                var link = document.createElement("a");
                if (link.download !== undefined) {
                // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportedFilenmae);
                link.style.visibility = "hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                }
            }
        },
        // convert object to csv
        convertToCSV(objArray) {
            var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
            var str = "";
            for (var i = 0; i < array.length; i++) {
                var line = "";
                for (var index in array[i]) {
                if (line != "") line += ",";
                line += array[i][index];
                }
                str += line + "\r\n";
            }
            return str;
        },
        showAnswers(answers) {
            this.answers = answers;
            this.answersDialog = true;
        },
        userClick(userId) {
            this.userDetails = this.lookupUserDetails(userId),
            this.userDialog = true;
        },
        formAction(action, item) {
            this.formType = action;
            if (action === 'Add') {
                this.passForm = new PASSFORM(null, null, null, null, null, null, null, null, null, null, null, null); // clear pass form
            } else {
                this.passForm = new PASSFORM(item.id, item.userId, item.eventId, item.eventDate, item.venueId, item.status, item.answers, item.note, item.createdUserId, item.createdDatetime, item.updatedUserId, item.updatedDatetime);
            }
            this.showForm = true; // show form
        },
        async updatePass() {
            if (this.$refs.passes.validate()) {
                var result = await this.updateDocument('passes', this.passForm); // update pass through mixins
                if (result.code === 1) { // if update pass was successful
                    this.snackbar = new SNACKBAR(true,'success','Pass Updated',2000); // update snack bar with success
                    this.snackbarKey++ // update snack bar component key to clear component
                    this.passForm = new PASSFORM(null, null, null, null, null, null, null, null, null, null, null, null); // clear pass form
                    this.showForm = false; // hide form
                } else {
                    this.snackbar = new SNACKBAR(true,'errorSnackbar','Error Updating Pass',3000); // update snack bar with error
                    this.snackbarKey++ // update snack bar component key to clear component
                }
            }else{
                this.snackbar = new SNACKBAR(true,'errorSnackbar','Fill in every field in order to proceed.',3000); // update snack bar with error
                this.snackbarKey++ 
            }
        },
        deletePass(id, name) {
            this.deleteDialog = true;
            this.deleteName = this.lookupUser(name, 'fullName');
            this.deleteId = id;
        },        
        async yesAction() {
            var result = await this.deleteDocument('passes', this.deleteId); // delete pass through mixins
            if (result.code === 1) {
                this.deleteDialog = false;
                this.showForm = false;
                this.snackbar = new SNACKBAR(true,'success','Pass Deleted',1000); // update snack bar with success
                this.snackbarKey++ // update snack bar component key to clear component
            } else {
                this.deleteDialog = false;
                this.showForm = false;
                this.snackbar = new SNACKBAR(true,'errorSnackbar','Error Deleting Pass',3000); // update snack bar with error
                this.snackbarKey++ // update snack bar component key to clear component
            }
        },
         noAction(){
            this.deleteDialog = false;
            this.deleteName = '';
            this.deleteId = '';
        }
    },
    created() {
        // on create set default data objects
        this.passForm = new PASSFORM(null, null, null, null, null, null, null, null, null, null, null, null); // set default
        this.snackbar = new SNACKBAR(false,'black','',3000); // set default
    },
    async mounted() { 
        this.readDocuments('questions', 'questions'); // get list of passs from database
        this.readDocuments('venues', 'venues'); // get list of passs from database
        this.readDocuments('events', 'events'); // get list of passs from database
        this.readDocuments('users', 'users'); // get list of passs from database
        this.readDocuments('passes', 'passes'); // get list of passs from database
        var itemsPerPage
        switch (this.$vuetify.breakpoint.name) {
            case 'xs': itemsPerPage = 10; break;
            case 'sm': itemsPerPage = 10; break;
            case 'md': itemsPerPage = 10; break;
            case 'lg': itemsPerPage = 20; break;
            case 'xl': itemsPerPage = 20; break;
            default: itemsPerPage = 1;
        }
        this.itemsPerPage = itemsPerPage
    }
}
</script>

<style scoped>
.v-data-table-header {
    vertical-align: bottom;
    text-transform: uppercase !important;
}
</style>