import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/analytics";

// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: "AIzaSyBdyNBqJFO91b9pjDD2LasNsVyZqBXKHvU",
    authDomain: "wru-events.firebaseapp.com",
    databaseURL: "https://wru-events.firebaseio.com",
    projectId: "wru-events",
    storageBucket: "wru-events.appspot.com",
    messagingSenderId: "280050007128",
    appId: "1:280050007128:web:3d83431fbc9293af785e2d"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();
const currentUser = auth.currentUser;

export { db, storage, auth, currentUser };
